@tailwind base;
@tailwind components;

html {
  @apply h-full text-base text-scout-black bg-scout-gray-light overflow-x-hidden;
}

bold {
  @apply font-bold;
}

/* Switch */
.toggle-switch {
  appearance: none;
  height: 1.7rem;
  width: 1.7rem;
  top: 50%;
  transform: translateX(10%) translateY(-50%);
  @apply transition-all duration-75 absolute bg-white rounded-full outline-none;

  & + label {
    @apply h-8 w-full bg-scout-gray rounded-md;
  }

  &:checked {
    transform: translateX(125%) translateY(-50%);
  }

  &:checked:enabled + label {
    @apply bg-scout-blue;
  }

  &:focus + label {
    @apply border-2 border-scout-blue;
  }

  &:checked[aria-invalid="true"] + label {
    @apply bg-scout-red;
  }
}

/* Modal */
.modal-overlay {
  @apply h-full fixed inset-0 z-20 bg-scout-gray-transparent;
}

.modal-content {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  @apply fixed outline-none w-full max-w-xl;
}

/* Buttons */
.btn {
  @apply h-12 px-4 rounded font-medium select-none whitespace-no-wrap
        flex justify-center items-center  duration-75 transition-colors
        outline-none border-2 border-transparent;
  &:focus {
    @apply outline-none shadow-outline;
  }

  &:focus:active {
    @apply shadow-none;
  }

  &:disabled {
    @apply opacity-25 cursor-default;
  }
}

@each $color in (red, green, blue, yellow, orange) {
  /* Solid background */
  .btn-$color {
    @apply bg-scout-$color text-white shadow-indigo;

    &:enabled:hover {
      @apply bg-scout-$(color)-light;
    }

    &:enabled:active {
      @apply bg-scout-$(color)-dark;
    }
  }
  /* Clear background */
  .btn-$(color)-clear {
    @apply text-scout-$color;

    &:enabled:hover {
      @apply text-scout-$(color)-light;
    }

    &:enabled:active {
      @apply text-scout-$(color)-dark;
    }
  }
}

@variants group-focus, group-hover {
  .zoom {
    transform: scale(1.25);
  }
}

/* Toast */
.Toastify__toast-container {
  @apply font-semibold;
}

@screen sm {
  .Toastify__toast {
    @apply rounded shadow-md;
  }
}

.Toastify__toast--error {
  @apply bg-red-300 text-red-700;
}
.Toastify__toast--warning {
  @apply bg-yellow-300 text-yellow-700;
}
.Toastify__toast--success {
  @apply bg-green-300 text-green-700;
}

@tailwind utilities;

.bb text {
  @apply font-sans font-medium text-sm fill-current !important;
}

.bb-tooltip th {
  @apply text-base !important;
  background-color: rgba(54, 93, 245, 0.5) !important;
  color: white;
}

.bb-tooltip td.value,
td.name {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bb-color-pattern {
  background-image: url("#365DF5;#00bc8a;#FF005F;#FFC881;#FF50A2;#A2A9B9;#F192E4;#6479FF;#8951c4;#6bbe7f;#bbbb") !important;
}

@keyframes pulse-load {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

.loader {
  animation: 2s ease-in-out infinite pulse-load;
  background-blend-mode: multiply;
}
.bb-grid-y line {
  stroke: #c4c4c4 !important;
  stroke-width: 2px;
}

.rule-line-even {
  & line  {
    stroke-dasharray: 1%;
    @apply stroke-current text-scout-gray-dark !important;
  }

  & text {
    text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
    @apply text-sm opacity-75  !important;
  }
}

.rule-line-odd {
  & line  {
    stroke-dasharray: 3%;
    @apply stroke-current text-scout-gray-dark !important;
  }

  & text {
    text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
    @apply text-sm opacity-75  !important;
  }
}


.hover-line {
  & line {
    stroke-dasharray: 0.5%;
    @apply stroke-current text-scout-green-light !important;
  }

  & text {
    text-shadow: 0px 0px 1px rgba(150, 150, 150, 1);
    @apply text-sm opacity-75 !important;
  }
}

.rule-region {
  & rect {
    @apply fill-current text-scout-green-light !important;
  }
}

.region-green {
  & rect {
    @apply fill-current text-scout-green;
  }
}

.region-yellow {
  & rect {
    @apply fill-current text-scout-yellow-light;
  }
}

.region-orange {
  & rect {
    @apply fill-current text-scout-orange-adjusted;
  }
}

.region-red {
  & rect {
    @apply fill-current text-scout-red-dark;
  }
}

.bb-region.bb-region-green {
  @apply fill-current text-scout-green;
}

.bb-region.bb-region-yellow {
  @apply fill-current text-scout-yellow;
}

.bb-region.bb-region-red {
  @apply fill-current text-scout-red;
}

.bb-region rect {
  fill-opacity: 0.2 !important;
}

.bb-grid-freezing > line {
  @apply text-blue-600 stroke-current !important;
}

.bb-grid-zero > line {
  @apply text-gray-600 stroke-current !important;
}

.field-capacity-one > line {
  @apply text-scout-green-dark  stroke-current !important;
}

.bb-line {
  @apply stroke-2 !important;
}

.text-graph-gray {
  color: #A2A9B9;
}

#eula-content {
  white-space: pre-line;
}

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}